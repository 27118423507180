import React, { Component } from 'react';
import $ from 'jquery';
import { Card } from 'primereact/card';

//act as proxy with gcloud function to get token so we can keep secrets hidden
class LoadStats extends Component {
	constructor(props) {
		super(props);
		this.queryStats = this.queryStats.bind(this);
	}

	queryStats = (ownProps) => {
		const session = JSON.parse(localStorage.getItem('session'));
 
        $.ajax({
			type: 'POST', 
			url: 'https://us-central1-strava-263517.cloudfunctions.net/get_stats',
			data: {
				athlete_id: session.athlete.id
			}
		}).done((data) => {
			let monthCounter = 0;
			let yearCounter = 0;
			let lastYear = 0
			data[0].map(activity => {
				activity.when = activity.year + "-" + ('0'+activity.month).slice(-2);

				activity.ytdYear = Math.floor(monthCounter / 12) + 1;
				if (activity.year !== lastYear) {
					lastYear = activity.year;
					yearCounter++;
				}
				monthCounter++;
				activity.monthCounter = monthCounter;
				activity.yearCounter = yearCounter;

				activity.movingSumFormatted = this.formatTime(activity.moving_sum);
				activity.movingAvgFormatted = this.formatTime(activity.moving_avg);
				activity.movingSoloFormatted = this.formatTime(activity.solo_moving_sum);
				activity.movingVirtualFormatted = this.formatTime(activity.virtual_moving_sum);
				activity.movingGroupFormatted = this.formatTime(activity.group_moving_sum);

				activity.movingSumDecimalized = this.decimalizeTime(activity.moving_sum);
				activity.movingAvgDecimalized = this.decimalizeTime(activity.moving_avg);
				activity.movingSoloDecimalized = this.decimalizeTime(activity.solo_moving_sum);
				activity.movingVirtualDecimalized = this.decimalizeTime(activity.virtual_moving_sum);
				activity.movingGroupDecimalized = this.decimalizeTime(activity.group_moving_sum);				

				activity.distanceSumFormatted = this.formatMetersToMiles(activity.distance_sum);
				activity.distanceAvgFormatted = this.formatMetersToMiles(activity.distance_avg);
				activity.distanceSoloFormatted = this.formatMetersToMiles(activity.solo_distance_sum);
				activity.distanceVirtualFormatted = this.formatMetersToMiles(activity.virtual_distance_sum);
				activity.distanceGroupFormatted = this.formatMetersToMiles(activity.group_distance_sum);

				activity.distanceSumCalculated = this.metersToMiles(activity.distance_sum);
				activity.distanceAvgCalculated = this.metersToMiles(activity.distance_avg);
				activity.distanceSoloCalculated = this.metersToMiles(activity.solo_distance_sum);
				activity.distanceVirtualCalculated = this.metersToMiles(activity.virtual_distance_sum);
				activity.distanceGroupCalculated = this.metersToMiles(activity.group_distance_sum);

				activity.elevationSumFormatted = this.formatMetersToFeet(activity.elevation_gain_sum);
				activity.elevationAvgFormatted = this.formatMetersToFeet(activity.elevation_gain_avg);
				activity.elevationSoloFormatted = this.formatMetersToFeet(activity.solo_elevation_gain_sum);
				activity.elevationVirtualFormatted = this.formatMetersToFeet(activity.virtual_elevation_gain_sum);
				activity.elevationGroupFormatted = this.formatMetersToFeet(activity.group_elevation_gain_sum);

				activity.elevationSumCalculated = this.metersToFeet(activity.elevation_gain_sum);
				activity.elevationAvgCalculated = this.metersToFeet(activity.elevation_gain_avg);
				activity.elevationSoloCalculated = this.metersToFeet(activity.solo_elevation_gain_sum);
				activity.elevationVirtualCalculated = this.metersToFeet(activity.virtual_elevation_gain_sum);
				activity.elevationGroupCalculated = this.metersToFeet(activity.group_elevation_gain_sum);

				return true;
			});	
			localStorage.setItem('activityStats', JSON.stringify(data[0]));
			ownProps.history.push('/reports');
		}).fail(function (jqXHR, textStatus) {
			ownProps.history.push('/error');
		}); //TODO: fix embedded array
	}

	decimalizeTime(columnValue) {
		const hours = Math.floor(columnValue / 3600);
		const minutes = Math.round(Math.floor((columnValue - (hours * 3600))  / 60) / 60 * 100) / 100;
		return hours + minutes;
	}

	formatTime(columnValue) {
		const hours = Math.floor(columnValue / 3600);
		const minutes = ('0' + Math.floor((columnValue - (hours * 3600))  / 60)).slice(-2);
		return hours + ':' + minutes;
    }

	metersToMiles(columnValue) {
		return Math.floor(columnValue * 0.000621371)
	}

	formatMetersToMiles(columnValue) {
		return Math.floor(columnValue * 0.000621371).toLocaleString()
	}

	metersToFeet(columnValue) {
		return Math.floor(columnValue * 3.28084)
	}

	formatMetersToFeet(columnValue) {
		return Math.floor(columnValue * 3.28084).toLocaleString()
	}

	render () {
		this.queryStats(this.props);
		return (<Card>Loading Stats...</Card>)
	}
}
 
export default LoadStats;