import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';

class Reports extends Component {
	constructor(props) {
        super(props);
        this.state = {
        	refresh:false,
        	activities:[],
        	groupBy:'ytdYear',
	        width: window.innerWidth};
		
		//used for reformatting data into human readable
		this.timeTemplate = this.timeTemplate.bind(this);
		this.metersTemplate = this.metersTemplate.bind(this);
		this.feetTemplate = this.feetTemplate.bind(this);

		//used for large data table grouping
		this.headerTemplate = this.headerTemplate.bind(this);
		this.footerTemplate = this.footerTemplate.bind(this);

		//used for smaller format views
		this.dataViewItemTemplate = this.dataViewItemTemplate.bind(this);
    }

	componentWillMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	// make sure to remove the listener
	// when the component is not mounted anymore
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		let newState = this.state;
		newState.width = window.innerWidth;
		this.setState(newState);
	}

	handleGroupByChange = () => {
		let newState = this.state;
		newState.groupBy = (newState.groupBy === 'year') ? 'ytdYear' : 'year';
		this.setState(newState);
	}

	componentDidMount() {
    	//TODO: create extensions for this
    	//TODO: have data cached
		const demo = localStorage.getItem('demo');
		const activityStats = JSON.parse(localStorage.getItem('activityStats'));

		if (demo) this.setState({refresh:false, activities: activityStats, width: window.innerWidth});
		else this.setState({refresh:true, activities: activityStats, width: window.innerWidth});
    }

    formatTime(columnValue) {
		const hours = Math.floor(columnValue / 3600);
		const minutes = ('0' + Math.floor((columnValue - (hours * 3600))  / 60)).slice(-2);
		return hours + ':' + minutes;
    }

    timeTemplate(rowData, column) {
    	const columnValue = rowData[column.field];
    	return this.formatTime(columnValue)
    }

    formatMetersToMiles(columnValue) {
    	return Math.floor(columnValue * 0.000621371).toLocaleString()
    }

    formatMetersToFeet(columnValue) {
    	return Math.floor(columnValue * 3.28084).toLocaleString()
    }

    metersTemplate(rowData, column) {
    	const columnValue = rowData[column.field];
    	return this.formatMetersToMiles(columnValue)
    }

    feetTemplate(rowData, column) {
    	const columnValue = rowData[column.field];
    	return this.formatMetersToFeet(columnValue)
    }

	headerTemplate(data) {
		if (this.state.groupBy === 'year') {
			return <b>Calendar Year: {data.year}</b>;
		} else {
			return <b>Year To Date: {data.ytdYear}</b>;
		}
	}

	//TODO: make a css file
	footerTemplate(data, index) {
		return ([
			<td key={data.year + '_footerTotalLabel'}><b>Totals</b></td>,
			<td key={data.year + '_footerTotalDaysValue'} style={{textAlign: 'right'}}><b>{this.calculateDaysTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalRidesValue'} style={{textAlign: 'right'}}><b>{this.calculateRidesTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalMovingValue'} style={{textAlign: 'right'}}><b>{this.calculateMovingTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerSpace1Label'} style={{textAlign: 'right'}}></td>,
			<td key={data.year + '_footerTotalSoloTimeValue'} style={{textAlign: 'right'}}><b>{this.calculateSoloMovingTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalVirtualTimeValue'} style={{textAlign: 'right'}}><b>{this.calculateVirtualMovingTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalGroupTimeValue'} style={{textAlign: 'right'}}><b>{this.calculateGroupMovingTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalDistanceValue'} style={{textAlign: 'right'}}><b>{this.calculateDistanceTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalSpace2Label'} style={{textAlign: 'right'}}></td>,
			<td key={data.year + '_footerTotalSoloDistanceValue'} style={{textAlign: 'right'}}><b>{this.calculateSoloDistanceTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalVirtualDistanceValue'} style={{textAlign: 'right'}}><b>{this.calculateVirtualDistanceTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalGroupDistanceValue'} style={{textAlign: 'right'}}><b>{this.calculateGroupDistanceTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalElevationValue'} style={{textAlign: 'right'}}><b>{this.calculateElevationTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalSpace3Label'} style={{textAlign: 'right'}}></td>,
			<td key={data.year + '_footerTotalSoloElevationValue'} style={{textAlign: 'right'}}><b>{this.calculateSoloElevationTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalVirtualElevationValue'} style={{textAlign: 'right'}}><b>{this.calculateVirtualElevationTotal(data.year, data.ytdYear)}</b></td>,
			<td key={data.year + '_footerTotalGroupElevationValue'} style={{textAlign: 'right'}}><b>{this.calculateGroupElevationTotal(data.year, data.ytdYear)}</b></td>
		]);
	}

	//TODO: calculate functions are all the same, different field.  refactor
	calculateDaysTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.total_days;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.total_days;
					}
				}
			}
		}
		return total;
	}

	calculateRidesTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.total_rides;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.total_rides;
					}
				}
			}
		}
		return total;
	}

	calculateMovingTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.moving_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.moving_sum;
					}
				}
			}
		}
		return this.formatTime(total);
	}

	calculateSoloMovingTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.solo_moving_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.solo_moving_sum;
					}
				}
			}
		}
		return this.formatTime(total);
	}
	
	calculateVirtualMovingTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.virtual_moving_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.virtual_moving_sum;
					}
				}
			}
		}
		return this.formatTime(total);
	}

	calculateGroupMovingTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.group_moving_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.group_moving_sum;
					}
				}
			}
		}
		return this.formatTime(total);
	}

	calculateDistanceTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.distance_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.distance_sum;
					}
				}
			}
		}
		return this.formatMetersToMiles(total);
	}

	calculateSoloDistanceTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.solo_distance_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.solo_distance_sum;
					}
				}
			}
		}
		return this.formatMetersToMiles(total);
	}

	calculateVirtualDistanceTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.virtual_distance_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.virtual_distance_sum;
					}
				}
			}
		}
		return this.formatMetersToMiles(total);
	}

	calculateGroupDistanceTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.group_distance_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.group_distance_sum;
					}
				}
			}
		}
		return this.formatMetersToMiles(total);
	}

	calculateElevationTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.elevation_gain_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.elevation_gain_sum;
					}
				}
			}
		}
		return this.formatMetersToFeet(total);
	}

	calculateSoloElevationTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.solo_elevation_gain_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.solo_elevation_gain_sum;
					}
				}
			}
		}
		return this.formatMetersToFeet(total);
	}

	calculateVirtualElevationTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.virtual_elevation_gain_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.virtual_elevation_gain_sum;
					}
				}
			}
		}
		return this.formatMetersToFeet(total);
	}

	calculateGroupElevationTotal(year, ytdYear) {
		let total = 0;

		if(this.state.activities) {
			for(let activity of this.state.activities) {
				if (this.state.groupBy === 'year') {
					if(activity.year === year) {
						total += activity.group_elevation_gain_sum;
					}
				} else {
					if(activity.ytdYear === ytdYear) {
						total += activity.group_elevation_gain_sum;
					}
				}
			}
		}
		return this.formatMetersToFeet(total);
	}

	dataViewItemTemplate(activity, layout) {
		return (
			<Card title={activity.when}>
				<div>Days: {activity.total_days}</div>
				<div>Rides: {activity.total_rides}</div>

				<div>Total Moving: {activity.movingSumFormatted}</div>
				<div>Daily Average: {activity.movingAvgFormatted}</div>
				<div>Solo Moving: {activity.movingSoloFormatted}</div>
				<div>Virtual Moving: {activity.movingVirtualFormatted}</div>
				<div>Group Moving: {activity.movingGroupFormatted}</div>

				<div>Total Distance: {activity.distanceSumFormatted}</div>
				<div>Daily Average: {activity.distanceAvgFormatted}</div>
				<div>Solo Distance: {activity.distanceSoloFormatted}</div>
				<div>Virtual Distance: {activity.distanceVirtualFormatted}</div>
				<div>Group Distance: {activity.distanceGroupFormatted}</div>

				<div>Total Elevation: {activity.elevationSumFormatted}</div>
				<div>Daily Average: {activity.elevationAvgFormatted}</div>
				<div>Solo Elevation: {activity.elevationSoloFormatted}</div>
				<div>Virtual Elevation: {activity.elevationVirtualFormatted}</div>
				<div>Group Elevation: {activity.elevationGroupFormatted}</div>
				
			</Card>
		);
	}
	//TODO: fix scrollheight
	//TODO: data list for mobile
	render() {
		const isMobile = this.state.width <= 500;

		const homeButton = <Button icon="pi pi-home" style={{'float':'left', 'marginRight':'5px'}} onClick={() => this.props.history.push('/')} />;
		const refreshButton = (this.state.refresh) ? <Button icon="pi pi-refresh" style={{'float':'right'}} onClick={() => this.props.history.push('/download_data')} /> : '';
		const chartButton = <Button icon="pi pi-chart-line" style={{'float':'left'}} onClick={() => this.props.history.push('/charts')} />;
		const calendarButton = <Button icon="pi pi-calendar" style={{'float':'right', 'marginRight':'5px'}} onClick={() => this.handleGroupByChange()} />;
		
		if (isMobile) {
			const header = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>{homeButton} {chartButton} Cycling Activities {refreshButton}</div>
		
			return (
				<DataView 
					header={header}
					value={this.state.activities} 
					sortOrder={0} sortField="when" 
					paginator={true} rows={1}
					itemTemplate={this.dataViewItemTemplate}>
				</DataView>);
		} else {
			const header = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>{homeButton} {chartButton} Cycling Activities {refreshButton} {calendarButton}</div>
		
			const headerGroup = <ColumnGroup>
				<Row>
					<Column header="" />
					<Column header="Totals" colSpan={2} />
					<Column header="Moving (Hours)" colSpan={5} />
					<Column header="Distance (Miles)" colSpan={5} />
					<Column header="Elevation (Feet)" colSpan={5} />
				</Row>
				<Row>
					<Column header="When" />
					<Column header="Days" />
					<Column header="Rides" />
					<Column header="Total" />
					<Column header="Daily Avg" />
					<Column header="Solo" />
					<Column header="Virtual" />
					<Column header="Group" />
					<Column header="Total" />
					<Column header="Daily Avg" />
					<Column header="Solo" />
					<Column header="Virtual" />
					<Column header="Group" />
					<Column header="Total" />
					<Column header="Daily Avg" />
					<Column header="Solo" />
					<Column header="Virtual" />
					<Column header="Group" />
				</Row>
			</ColumnGroup>;

			return (
				<DataTable 
					header={header}
					value={this.state.activities} 
					rowGroupMode="subheader" sortField="when" 
					headerColumnGroup={headerGroup}
					sortOrder={0} groupField={this.state.groupBy} resizableColumns={true} 
					rowGroupHeaderTemplate={this.headerTemplate} 
					rowGroupFooterTemplate={this.footerTemplate}>
					<Column header="When" field="when" />
					<Column header="Days" field="total_days" style={{textAlign: 'right'}} />
					<Column header="Rides" field="total_rides" style={{textAlign: 'right'}} />
					<Column header="Total Hours" field="movingSumFormatted" style={{textAlign: 'right'}} />
					<Column header="Daily Avgerage" field="movingAvgFormatted" style={{textAlign: 'right'}} />
					<Column header="Solo" field="movingSoloFormatted" style={{textAlign: 'right'}} />
					<Column header="Virtual" field="movingVirtualFormatted" style={{textAlign: 'right'}} />
					<Column header="Group" field="movingGroupFormatted" style={{textAlign: 'right'}} />
					<Column header="Total Miles" field="distanceSumFormatted" style={{textAlign: 'right'}} />
					<Column header="Daily Avgerage" field="distanceAvgFormatted" style={{textAlign: 'right'}} />
					<Column header="Solo" field="distanceSoloFormatted" style={{textAlign: 'right'}} />
					<Column header="Virtual" field="distanceVirtualFormatted" style={{textAlign: 'right'}} />
					<Column header="Group" field="distanceGroupFormatted" style={{textAlign: 'right'}} />
					<Column header="Total Feet" field="elevationSumFormatted" style={{textAlign: 'right'}} />
					<Column header="Daily Avgerage" field="elevationAvgFormatted" style={{textAlign: 'right'}} />
					<Column header="Solo" field="elevationSoloFormatted" style={{textAlign: 'right'}} />
					<Column header="Virtual" field="elevationVirtualFormatted" style={{textAlign: 'right'}} />
					<Column header="Group" field="elevationGroupFormatted" style={{textAlign: 'right'}} />
				</DataTable>
			);
	}	}
}

export default Reports;