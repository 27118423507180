import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Redirect, Switch, BrowserRouter as Router } from 'react-router-dom';
import GA from './utils/GoogleAnalytics'

import Intro from './Intro';
import Demo from './Demo';
import Login from './Login';
import Logout from './Logout';
import DownloadData from './DownloadData';
import LoadStats from './LoadStats';
import ExchangeToken from './ExchangeToken';
import Reports from './Reports';
import Charts from './Charts';
import Error from './Error';
import Notfound from './NotFound';

import './index.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css"
//import * as serviceWorker from './serviceWorker';

//TODO: login check
const session = sessionStorage.getItem('session')

function PrivateRoute ({component: Component, authed, ...rest}) {
	return (
		<Route
			{...rest}
			render={(props) => authed === true
				? <Component {...props} />
				: <Redirect to={{pathname: '/login'}} />
			}
		/>
	)
}

const routing = (
	<Router>
        { GA.init() && <GA.RouteTracker /> }
		<Switch>
			<Route exact path = '/' component={Intro} />
			<Route path = '/intro' component={Intro} />
			<Route path = '/demo' component={Demo} />
			<Route path = '/login' component={Login} />
			<Route path = '/logout' component={Logout} />
			<Route path = '/exchange_token' component={ExchangeToken} />
			<Route path = '/download_data' component={DownloadData} />
			<Route path = '/load_stats' component={LoadStats} />
			<Route path = '/reports' component={Reports} />
			<Route path = '/charts' component={Charts} />
			<Route path = '/not_found' component={Notfound} />
			<Route path = '/error' component={Error} />
			<Redirect to = '/not_found' />
		</Switch>
	</Router>
);
ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
