import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Steps } from 'primereact/steps';
//import { Button } from 'primereact/button';

class Intro extends Component {
	render () {
		//TODO: make component and include
		const steps = [
			{label: 'Login to Strava'},
			{label: 'Download activities'},
			{label: 'View Data'}
		];
		return (
			<Card title="Welcome!">
				This site is meant to pull cycling data from strava and display stats. <br />
				<Steps model={steps} activeIndex={0} readOnly={true} />
				<Link to="/login">Click here to Login to Strava</Link> <br />
				<br />
				{"Don't have a strava account?"} <br />
				<Link to="/demo">Click here to view a demo</Link>
			</Card>
		);
	}

	requestStravaLogin () {
		//TODO: put these in a config file
		const client_id = '40467';
		const redirect_uri = 'http://localhost:3000/exchange_token';
		const authorization_uri = 'http://www.strava.com/oauth/authorize?client_id=' + client_id + '&response_type=code&redirect_uri=' + redirect_uri + '&approval_prompt=force&scope=activity:read_all'; 

		localStorage.clear();
		window.location.href = authorization_uri;
	}
}

export default Intro;