import React from 'react';

function Logout() {
	localStorage.clear();
	
	return (
		<div>
		  Your session has ended
		</div>
	); 
}
 
export default Logout;