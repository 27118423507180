import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { DataView } from 'primereact/dataview';

class Charts extends Component {
	constructor(props) {
        super(props);

        this.state = {
        	refresh:false,
        	activities:[],
        	groupBy:'ytdYear',
        	yearListCount: 1,
        	maxYearListCount: 1,
        	groups: [],
        	first: 0,
        	totalRecords: 0,
	        width: window.innerWidth};
		this.dataViewItemTemplate = this.dataViewItemTemplate.bind(this);
    }

	componentWillMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	// make sure to remove the listener
	// when the component is not mounted anymore
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		let newState = this.state;
		newState.width = window.innerWidth;
		this.setState(newState);
	}

	handleGroupByChange = () => {
		let newState = this.state;
		newState.groupBy = (newState.groupBy === 'year') ? 'ytdYear' : 'year';
		this.setState(newState);
	}

	addYearDisplay = () => {
		let newState = this.state;
		newState.yearListCount = ++newState.yearListCount;
		newState.totalRecords = --newState.totalRecords;
		if ((newState.first + newState.yearListCount) > newState.totalRecords) {
			newState.first = newState.first - 1;
		}
		this.setState(newState);
	}

	removeYearDisplay = () => {
		let newState = this.state;
		newState.yearListCount = --newState.yearListCount;
		newState.totalRecords = ++newState.totalRecords;
		this.setState(newState);
	}

	setPage = (e) => {
		let newState = this.state;
		newState.first = e.first;
		this.setState(newState);
	}
	componentDidMount() {
		const demo = localStorage.getItem('demo');
		const activityStats = JSON.parse(localStorage.getItem('activityStats'));

		const groups = [...new Set(activityStats.map(activity => {
			if (this.state.groupBy === 'ytdYear') return activity.ytdYear;
			else if (this.state.groupBy === 'year') return activity.yearCounter;
			else return false;
		}))];

		let newState = this.state;
		newState.activities = activityStats;
        newState.maxYearListCount = groups.length;
        newState.groups = groups;
        newState.totalRecords = groups.length;
		newState.refresh = !demo;
		this.setState(newState);
    }

	dataViewItemTemplate(group, layout) {
		const stackedOptions = {
			tooltips: {
				mode: 'index',
				intersect: false
			},
			responsive: true,
			scales: {
				xAxes: [{
					stacked: true,
				}],
				yAxes: [{
					stacked: true
				}]
			}
		};

		const lineOptions = {
			tooltips: {
				mode: 'index',
				intersect: false
			},
			responsive: true,
			scales: {
				xAxes: [{
					stacked: false,
				}],
				yAxes: [{
					stacked: false
				}]
			}
		};

		let ytdYear = '';
		let year = '';
		let displayLabels = [];
		let movingDataTotalsDataset = [];
		let movingDataAveragesDataset = [];
		let distanceDataTotalsDataset = [];
		let distanceDataAveragesDataset = [];
		let elevationDataTotalsDataset = [];
		let elevationDataAveragesDataset = [];

		//green, yellow, blue, red, orange, black, purple, grey, light blue
		const colorStack = ['#66BB6A', '#FFCA28', '#42A5F5', '#CD201F', '#ED750A', '#000000', '#A336B4', '#FFC517', '#DCF7FA']

		const hasMultiple = (this.state.yearListCount > 1);

		for (let yearGroupI = 1; yearGroupI <= this.state.yearListCount; yearGroupI++) {
			const yearData = this.getYearData(yearGroupI + this.state.first)
			if ((this.state.yearListCount > 1 || this.state.groupBy === 'year') && yearData.monthCount === 12) {
				displayLabels = yearData.monthLabel;
			} else if (yearGroupI === 1 && this.state.yearListCount > 1 && yearData.monthCount === 12) {
				displayLabels = yearData.monthLabel;
			} else { //if (this.state.yearListCount === 1 && this.state.groupBy === 'ytdYear')
				ytdYear = yearData.ytdYear;
				year = yearData.year;
				displayLabels = yearData.whenLabel;
			}
			//TODO: find how to set labels per group instead of across
			const yearDisplay = yearGroupI;

			movingDataTotalsDataset.push(
				{
					type: 'bar',
				    label: (hasMultiple) ? 'Solo Total (' + yearDisplay + ')' : 'Solo Total',
				    backgroundColor: '#66BB6A',
					data: yearData.yearMovingSolo,
					stack: yearGroupI
				}, {
					type: 'bar',
					label: (hasMultiple) ? 'Virtual Total (' + yearDisplay + ')' : 'Virtual Total',
					backgroundColor: '#FFCA28',
					data: yearData.yearMovingVirtual,
					stack: yearGroupI
				}, {
					type: 'bar',
					label: (hasMultiple) ? 'Group Total (' + yearDisplay + ')' : 'Group Total',
					backgroundColor: '#42A5F5',
					data: yearData.yearMovingGroup,
					stack: yearGroupI
				});
			movingDataAveragesDataset.push({
					type: 'line',
					label: (hasMultiple) ? 'Ride Average (' + yearDisplay + ')' : 'Ride Average',
					borderColor: colorStack[yearGroupI - 1],
					borderWidth: 2,
					fill: false,
					data: yearData.yearMovingAvg
				});
			distanceDataTotalsDataset.push({
					type: 'bar',
				    label: (hasMultiple) ? 'Solo Total (' + yearDisplay + ')' : 'Solo Total',
				    backgroundColor: '#66BB6A',
					data: yearData.yearDistanceSolo,
					stack: yearGroupI
				}, {
					type: 'bar',
					label: (hasMultiple) ? 'Virtual Total (' + yearDisplay + ')' : 'Virtual Total',
					backgroundColor: '#FFCA28',
					data: yearData.yearDistanceVirtual,
					stack: yearGroupI
				}, {
					type: 'bar',
					label: (hasMultiple) ? 'Group Total (' + yearDisplay + ')' : 'Group Total',
					backgroundColor: '#42A5F5',
					data: yearData.yearDistanceGroup,
					stack: yearGroupI
				});
			distanceDataAveragesDataset.push({
					type: 'line',
					label: (hasMultiple) ? 'Ride Average (' + yearDisplay + ')': 'Ride Average',
					borderColor: colorStack[yearGroupI - 1],
					borderWidth: 2,
					fill: false,
					data: yearData.yearDistanceAvg
				});

			elevationDataTotalsDataset.push({
					type: 'bar',
				    label: (hasMultiple) ? 'Solo Total (' + yearDisplay + ')' : 'Solo Total',
				    backgroundColor: '#66BB6A',
					data: yearData.yearElevationSolo,
					stack: yearGroupI
				}, {
					type: 'bar',
					label: (hasMultiple) ? 'Virtual Total (' + yearDisplay + ')' : 'Virtual Total',
					backgroundColor: '#FFCA28',
					data: yearData.yearElevationVirtual,
					stack: yearGroupI
				}, {
					type: 'bar',
					label: (hasMultiple) ? 'Group Total (' + yearDisplay + ')' : 'Group Total',
					backgroundColor: '#42A5F5',
					data: yearData.yearElevationGroup,
					stack: yearGroupI
				});

			elevationDataAveragesDataset.push({
					type: 'line',
					label: (hasMultiple) ? 'Ride Average (' + yearDisplay + ')' : 'Ride Average',
					borderColor: colorStack[yearGroupI - 1],
					borderWidth: 2,
					fill: false,
					data: yearData.yearElevationAvg
				});
		}
		const movingDataTotals = {
			labels: displayLabels,
			datasets: movingDataTotalsDataset
		};

		const movingDataAverages = {
			labels: displayLabels,
			datasets: movingDataAveragesDataset
		};

		const distanceDataTotals = {
			labels: displayLabels,
			datasets: distanceDataTotalsDataset
		};

		const distanceDataAverages = {
			labels: displayLabels,
			datasets: distanceDataAveragesDataset
		};

		const elevationDataTotals = {
			labels: displayLabels,
			datasets: elevationDataTotalsDataset
		};

		const elevationDataAverages = {
			labels: displayLabels,
			datasets: elevationDataAveragesDataset
		};

		const subTitle = (this.state.groupBy === 'ytdYear') ? 'Year To Date: ' + ytdYear : 'Calendar Year: ' + year;
		return (
			<div className="p-grid">
				<div className="p-col-12 p-sm-12 p-md-6 p-lg-6">
					<Card title='Moving Time' subTitle={subTitle}>
						<Chart type="bar" data={movingDataTotals} options={stackedOptions} />
					</Card>
				</div>

				<div className="p-col-12 p-sm-12 p-md-6 p-lg-6">
					<Card title='Moving Average' subTitle={subTitle}>
						<Chart type="line" data={movingDataAverages} options={lineOptions} />
					</Card>
				</div>	

				<div className="p-col-12 p-sm-12 p-md-6 p-lg-6">
					<Card title='Distance Totals' subTitle={subTitle}>
						<Chart type="bar" data={distanceDataTotals} options={stackedOptions} />
					</Card>
				</div>

				<div className="p-col-12 p-sm-12 p-md-6 p-lg-6">
					<Card title='Distance Averages' subTitle={subTitle}>
						<Chart type="line" data={distanceDataAverages} options={lineOptions} />
					</Card>
				</div>

				<div className="p-col-12 p-sm-12 p-md-6 p-lg-6">
					<Card title='Elevation Totals'subTitle={subTitle}>
						<Chart type="bar" data={elevationDataTotals} options={stackedOptions} />
					</Card>
				</div>

				<div className="p-col-12 p-sm-12 p-md-6 p-lg-6">
					<Card title='Elevation Averages'subTitle={subTitle}>
						<Chart type="line" data={elevationDataAverages} options={lineOptions} />
					</Card>
				</div>
			</div>
		);
	}

	getYearData(yearQuery) {
		const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
		
		let yearData = this.state.activities.filter(activity => { 
			if (this.state.groupBy === 'ytdYear' && yearQuery === activity.ytdYear) return true;
			else if (this.state.groupBy === 'year' && yearQuery === activity.yearCounter) return true;
			else return false; 
		});
		if (this.state.groupBy === 'year') yearData = yearData.reverse();
		//need to ad blank entries to avoid incomplete charting
		const missingMonths = 12 - yearData.length;
		const monthCount = yearData.length;
		for (let i = 0; i < missingMonths; i++) {
			yearData.push({when: '-', month: '-'})
		}

		const whenLabel = yearData.map(activity =>  activity.when)
		const yearLabel = yearData.map(activity =>  activity.year)
		const monthLabel = yearData.map(activity =>  (activity.month === '-') ? '-' : monthNames[activity.month - 1])

		const yearMovingSolo = yearData.map(activity => activity.movingSoloDecimalized)
		const yearMovingVirtual = yearData.map(activity => activity.movingVirtualDecimalized)
		const yearMovingGroup = yearData.map(activity => activity.movingGroupDecimalized)
		const yearMovingAvg = yearData.map(activity => activity.movingAvgDecimalized)

		const yearDistanceSolo = yearData.map(activity => activity.distanceSoloCalculated)
		const yearDistanceVirtual = yearData.map(activity => activity.distanceVirtualCalculated)
		const yearDistanceGroup = yearData.map(activity => activity.distanceGroupCalculated)
		const yearDistanceAvg = yearData.map(activity => activity.distanceAvgCalculated)

		const yearElevationSolo = yearData.map(activity => activity.elevationSoloCalculated)
		const yearElevationVirtual = yearData.map(activity => activity.elevationVirtualCalculated)
		const yearElevationGroup = yearData.map(activity => activity.elevationGroupCalculated)
		const yearElevationAvg = yearData.map(activity => activity.elevationAvgCalculated)

		return {
			whenLabel:whenLabel,
			monthLabel:monthLabel,
			yearLabel:yearLabel,
			monthCount:monthCount,
			ytdYear:yearData[0].ytdYear,
			year:yearData[0].year,
			yearMovingSolo:yearMovingSolo,
			yearMovingVirtual:yearMovingVirtual,
			yearMovingGroup:yearMovingGroup,
			yearMovingAvg:yearMovingAvg,
			yearDistanceSolo:yearDistanceSolo,
			yearDistanceVirtual:yearDistanceVirtual,
			yearDistanceGroup:yearDistanceGroup,
			yearDistanceAvg:yearDistanceAvg,
			yearElevationSolo:yearElevationSolo,
			yearElevationVirtual:yearElevationVirtual,
			yearElevationGroup:yearElevationGroup,
			yearElevationAvg:yearElevationAvg
		};

	}

	//TODO: fix scrollheight
	//TODO: data list for mobile
	render() {
		//const isMobile = this.state.width <= 500;

		const homeButton = <Button icon="pi pi-home" style={{'float':'left', 'marginRight':'5px'}} onClick={() => this.props.history.push('/')} />;
		const refreshButton = (this.state.refresh) ? <Button icon="pi pi-refresh" style={{'float':'right'}} onClick={() => this.props.history.push('/download_data')} /> : '';
		const calendarButton = <Button icon="pi pi-calendar" style={{'float':'right', 'marginRight':'5px'}} onClick={() => this.handleGroupByChange()} />;
		const calendarPlusButton = <Button icon="pi pi-calendar-plus" style={{'float':'right', 'marginRight':'5px', 'display': (this.state.yearListCount < this.state.maxYearListCount) ? 'block' : 'none' }} onClick={() => this.addYearDisplay()} />;
		const calendarMinusButton = <Button icon="pi pi-calendar-minus" style={{'float':'right', 'marginRight':'5px', 'display': (this.state.yearListCount > 1) ? 'block' : 'none' }} onClick={() => this.removeYearDisplay()} />;
		const tableButton = <Button icon="pi pi-table" style={{'float':'left'}} onClick={() => this.props.history.push('/reports')} />;
		
		const header = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>{homeButton} {tableButton} Cycling Activities {refreshButton} {calendarPlusButton} {calendarMinusButton} {calendarButton}</div>	

		return ( 
			<DataView 
				header={header}
				value={this.state.groups} 
				sortOrder={0} sortField={this.state.groupBy}
				paginator={true} rows={1} paginatorPosition="both"
				totalRecords={this.state.totalRecords}
				first={this.state.first} onPage={(e) => this.setPage(e)}
				itemTemplate={this.dataViewItemTemplate}>
			</DataView>
		);
	}
}

export default Charts;