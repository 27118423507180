//this file is used as a redirect to strava
function Login () {
	//TODO: put these in a config file
	//us-central1-strava-263517.cloudfunctions.net
	const client_id = '40467';
	const redirect_uri = 'http://www.kickassninja.com/exchange_token';
	const authorization_uri = 'https://www.strava.com/oauth/authorize?client_id=' + client_id + '&response_type=code&redirect_uri=' + redirect_uri + '&approval_prompt=force&scope=activity:read_all'; 

	localStorage.clear();
	window.location.href = authorization_uri;
	return 'redirecting...'
}

export default Login;