import React from 'react';
import { Redirect } from 'react-router-dom';

//act as proxy with gcloud function to get token so we can keep secrets hidden
function Demo() {
	localStorage.setItem('session', '{"athlete":{"id":1032895}}');
	localStorage.setItem('demo', 'true')

	return <Redirect to='load_stats' />
}
 
export default Demo;