import React, { Component } from 'react';
import $ from 'jquery';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar'
import { Steps } from 'primereact/steps';

export class DownloadData extends Component {
	constructor(props) {
		super(props);
		this.state = {updatedRecords: 0, updating:false};
		this.downloadActivities = this.downloadActivities.bind(this);
	}

	downloadActivities = (ownProps, accessToken, athleteID, page) => {
        if (this.state.updating) {
            return
        } else {
            this.setState({updatedRecords: 0, updating:true});
            $.ajax({
                type: 'POST', 
                url: 'https://us-central1-strava-263517.cloudfunctions.net/download_archive',
                data: {
                    access_token: accessToken,
                    athlete_id: athleteID,
                    page: page
                }
            }).done(function(res) {
                //TODO: fix stupid variable names
                const recordsUpdated = JSON.parse(res).downloaded;
                if (recordsUpdated === 100) {
                    this.setState({updatedRecords: (page * 100), updating:true});
                    this.downloadActivities(ownProps, accessToken, athleteID, ++page);
                } else {
                    //this.setState({updatedRecords: ((page-1) * 100) + recordsUpdated});
                    ownProps.history.push('/load_stats');
                }
            }.bind(this)).fail(function (jqXHR, textStatus) {
                ownProps.history.push('/error');
            });
        }
	}

	render() {
		const session = JSON.parse(localStorage.getItem('session'));
		this.downloadActivities(this.props, session.access_token, session.athlete.id, 1);
		
		const steps = [
			{label: 'Login to Strava'},
			{label: 'Download activities'},
			{label: 'View Data'}
		];
		const cardTitle = "Welcome " + session.athlete.firstname + "!";
		return (
			<Card title={cardTitle}>
				<Steps model={steps} activeIndex={1}/>
				Records Updated: {this.state.updatedRecords}
				<ProgressBar mode="indeterminate" />
			</Card>
		);
	}
}


export default DownloadData;
