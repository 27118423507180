import React, { Component } from 'react';
import queryString from 'query-string';
import $ from 'jquery';
import { Card } from 'primereact/card';

//act as proxy with gcloud function to get token so we can keep secrets hidden
class ExchangeToken extends Component {
	constructor(props) {
		super(props);
		
		this.getToken = this.getToken.bind(this);
	}

	getToken = (ownProps) => {
		const parsed = queryString.parse(window.location.search);

		localStorage.setItem('exchange', JSON.stringify(parsed));
		
		$.ajax({
			type: 'POST', url: 'https://us-central1-strava-263517.cloudfunctions.net/get_token', 
			data: {
				code: parsed.code
			},
		}).done(function(responseJson) {
			if (typeof responseJson.access_token !== 'undefined') {
				localStorage.setItem('session', JSON.stringify(responseJson));
				ownProps.history.push('/download_data');
			} else {
				ownProps.history.push('/error');
			}
		}).fail(function (jqXHR, textStatus) {
			ownProps.history.push('/error');
		});
	}

	render() {
		this.getToken(this.props);
		return (<Card>Loading Authentication...</Card>)
	}
}
 
export default ExchangeToken;